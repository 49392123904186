import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AlysPNG from '../img/assholePORTAPUTE.png';


const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    const handleResize = () => {
      checkScreenWidth();
    };

    checkScreenWidth();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuClick = () => {
    const container = document.getElementById('menuContainer');
    if (container) {
      container.classList.toggle('change');
    }

    setIsOpen(!isOpen);
  };

  return <div className="bodyHome">
    <nav className="navBar">
      <Link to="/" className="nobodyTitle">Nobody</Link>

      <div className={`navBar ${isOpen ? 'overlayActive' : ''}`}>
        {isMobile && (
          <div className="menuContainer" onClick={handleMenuClick}>
            <div className={`bar ${isOpen ? 'change' : ''}`} style={{ position: "relative", zIndex: 200 }}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        )}

        {isOpen && (
          <div className="overlay">
            <Link to="/abt">About me</Link>
            <Link to="/art">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}

        {!isMobile && (
          <div className="rightNavBar">
            <Link to="/abt" className="lien">About me</Link>
            <Link to="/art" className="lien">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}


      </div>
    </nav>

    <section className="box-img">
      <div>
        <h1 className='mainTitlePort'>portfolio<span className="colorYe">.</span></h1>
        <p className='hide'>Hi ! I'm Nobody, nice to see you here !</p>
        <div className="box-main">
        <Link to="/art" className="btnDiscover">Discover !</Link>
        </div>
      </div>


      <div className="imgContainer">
        <div className="ellipseYe"></div>
        <img src={AlysPNG} alt="Alys -OC- in PNG" className="mainImg" style={{
          maxWidth: '100%', 
          height: 'auto',   
          width: '100%',    
          maxHeight: '100vh' 
        }}/>
      </div>
    </section>


    <div className="footer0021">
      <div className="nbr">0021</div>
    </div>

  </div> 
};

export default HomePage;
