import React, {useState, useEffect}from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';


import AlysS from '../img/Alys&Sword.jpg';
import AlysBack from '../img/AlysBack.jpg';
import AlysFlower from '../img/AlysFlower.jpg';
import brocante from '../img/brocante.jpg';
import cheval from '../img/cheval.jpg';
import Cyanid from '../img/Cyanid.jpg';
import DieuMan from '../img/DieuMan.jpg';
import DieuWoman from '../img/DieuWoman.jpg';
import eze from '../img/eze.jpg';
import frr from '../img/frr.jpg';
import mesange from '../img/mesange.jpg';
import Nemesseg from '../img/Nemesseg.jpg';
import porte from '../img/porte.jpg';
import serpent from '../img/serpent.jpg';
import potitBB from '../img/potitBBplore.jpg';
import jsa from '../img/JSA.png';
import skiTEST from '../img/skiTEST1.png';


const ArtPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleMenuClick = () => {
    const container = document.getElementById('menuContainer');
    if (container) {
      container.classList.toggle('change');
    }

    setIsOpen(!isOpen);
  };

  return <div>
      <nav className="navBar">
      <Link to="/" className="nobodyTitle">Nobody</Link>

      <div className={`navBar ${isOpen ? 'overlayActive' : ''}`}>
        {isMobile && (
          <div className="menuContainer" onClick={handleMenuClick}>
            <div className={`bar ${isOpen ? 'change' : ''}`} style={{ position: "relative", zIndex: 200 }}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        )}

        {isOpen && (
          <div className="overlay">
            <Link to="/abt">About me</Link>
            <Link to="/art">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}

        {!isMobile && (
          <div className="rightNavBar">
            <Link to="/abt" className="lien">About me</Link>
            <Link to="/art" className="lien">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}


      </div>
    </nav>

    <section>
      <div className="titleAbtMeContainer">
        <h2>my<br />sweet art<span className="colorYe">.</span></h2>
      </div>
      <p className="lilPara lilFont">
        Below are some drawings made on a graphics tablet (HUION)
        I use references to improve myself.
        It's always difficult for me to draw only from imagination.
      </p>

      <div className="row">
        <div className="column">
          <img src={AlysS} alt="Alys -OC- with a sword" />
          <img src={Cyanid} alt="Cyanid -OC-" />
          <img src={Nemesseg} alt="Némesseg -OC-" />
          <img src={potitBB} alt="All of my principal OCs" />
        </div>

        <div className="column">
          <img src={DieuMan} alt="Dieu -OC- in his masc' form" />
          <img src={DieuWoman} alt="Dieu -OC- in his woman form" />
          <img src={AlysFlower} alt="Alys a-OC- with flowers" />
        </div>

        <div className="column">
          <img src={AlysBack} alt="Alys' back -OC-" />
          <img src={frr} alt="Clothes study" />
          <img src={eze} alt="Ezechiel -OC-" />
        </div>
      </div>
    </section>

    <section>
      <div>
        <h3 className="lilTitleArt">--- Oh my pen ---</h3>
        <p className="lilPara lilFont">
          I also draw in traditional
          It's more messy but I appreciate the change in style and materials.
        </p>
      </div>

      <div className="row">
        <div className="column">
          <img src={mesange} alt="Bird's study" />
        </div>

        <div className="column">
          <img src={serpent} alt="snake" />
          <img src={porte} alt="door" />
        </div>

        <div className="column">
          <img src={cheval} alt="horse" />
          <img src={brocante} alt="it's juste some mess" />
        </div>
      </div>
    </section>

    <section>
      <div>
        <h3 className="lilTitleArt">--- Logo ---</h3>
        <p className="lilPara lilFont">
          I made a few logo for some associations at my school.
        </p>
      </div>

      <div className="row">
        <div className="column">
          <img src={jsa} alt="JSA's logo" />
        </div>

        <div className="column">
          <img src={skiTEST} alt="skisen's logo" />
        </div>

        <div className="column">
        </div>
      </div>
    </section>


    <div className="contactContainer">
      <div className="marginContainer">
        <h3>CONTACT</h3>
        <p>
          If you have any questions about me or just want to say hello,
          feel free to contact me on intsagram or by email :
          email : clara.gille@orange.fr
        </p>
        <a href="https://www.instagram.com/nobodyisgone/?hl=fr"><i className="fa-brands fa-instagram icone"></i></a>
          <a href="https://www.linkedin.com/in/21claragille/"><i className="fa-brands fa-linkedin icone"></i></a>
      </div>

      <footer>
        <p>@ created by Clara Gille</p>
      </footer>
    </div>

  </div>;
};

export default ArtPage;
