import React from 'react';
import { Link } from 'react-router-dom';

const ProjectPage = () => {
  return <div>
  <nav class="navbar">
    <Link to="/" class="nobodyTitle">Nobody</Link>


    <div class="rightNavBar">
        <Link to="/abt">About me</Link>
        <Link to="/art">My art</Link>
        <Link to="/project">My projects</Link>
        <Link to="/contact" class="btnTouch">Get in touch !</Link>
    </div>
  </nav>
</div>;
};

export default ProjectPage;
