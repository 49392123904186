import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './pages/home';
import AboutPage from './pages/abt';
import ContactPage from './pages/contact';
import ArtPage from './pages/art';
import ProjectPage from './pages/project';

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/abt" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/art" component={ArtPage} />
        <Route path="/project" component={ProjectPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
