import React, {useState, useEffect}from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleMenuClick = () => {
    const container = document.getElementById('menuContainer');
    if (container) {
      container.classList.toggle('change');
    }

    setIsOpen(!isOpen);
  };

  return <div>
  <nav className="navBar">
      <Link to="/" className="nobodyTitle">Nobody</Link>

      <div className={`navBar ${isOpen ? 'overlayActive' : ''}`}>
        {isMobile && (
          <div className="menuContainer" onClick={handleMenuClick}>
            <div className={`bar ${isOpen ? 'change' : ''}`} style={{ position: "relative", zIndex: 200 }}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        )}

        {isOpen && (
          <div className="overlay">
            <Link to="/abt">About me</Link>
            <Link to="/art">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}

        {!isMobile && (
          <div className="rightNavBar">
            <Link to="/abt" className="lien">About me</Link>
            <Link to="/art" className="lien">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}


      </div>
    </nav>

    <section>
      <h2>Contact</h2>

      <p>If you want to contact me, feel free to do so!
        I'm open to discussion and commissions.
        For potential orders, I can create logos, artworks, and I'm also willing to step out of my comfort zone. 
        The price depends on various factors such as the size of the artwork, the quality, the desired style, the turnaround time, and more.
        You can see my work on  <Link to="/art" className="colorYe">My art</Link> page. </p>

      <p>My email is : clara.gille@orange.fr</p>

      <p>You can also join me on my social media, I'm on <a href="https://www.instagram.com/nobodyisgone/?hl=fr" className="colorYe">Instagram</a> and <a href="https://www.linkedin.com/in/21claragille/" className="colorYe">LinkedIn</a>.</p>

    </section>

    <div className="contactContainer">
      <div className="marginContainer">
        <h3>EMAIL & MEDIA</h3>
        <p>clara.gille@orange.fr</p>
          <a href="https://www.instagram.com/nobodyisgone/?hl=fr"><i className="fa-brands fa-instagram icone"></i></a>
          <a href="https://www.linkedin.com/in/21claragille/"><i className="fa-brands fa-linkedin icone"></i></a>
      </div>

      <footer>
        <p>@ created by Clara Gille</p>
      </footer>
    </div>

</div>;
};

export default ContactPage;
