import './App.css';
import React from 'react';
import AppRouter from './AppRouter';

const App = () => {
  return (
    <div>
      <AppRouter />
    </div>
  );
};

export default App;

