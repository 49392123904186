import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';


const AboutPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleMenuClick = () => {
    const container = document.getElementById('menuContainer');
    if (container) {
      container.classList.toggle('change');
    }

    setIsOpen(!isOpen);
  };

  return <div className="bodyAbt">
        <nav className="navBar">
      <Link to="/" className="nobodyTitle">Nobody</Link>

      <div className={`navBar ${isOpen ? 'overlayActive' : ''}`}>
        {isMobile && (
          <div className="menuContainer" onClick={handleMenuClick}>
            <div className={`bar ${isOpen ? 'change' : ''}`} style={{ position: "relative", zIndex: 200 }}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        )}

        {isOpen && (
          <div className="overlay">
            <Link to="/abt">About me</Link>
            <Link to="/art">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}

        {!isMobile && (
          <div className="rightNavBar">
            <Link to="/abt" className="lien">About me</Link>
            <Link to="/art" className="lien">My art</Link>
            <Link to="/contact" className="btnTouch">Get in touch !</Link>
          </div>
        )}


      </div>
    </nav>

    <section className="section lilFont">
      <h2>me<span className="colorYe">.</span></h2>

      <div className="txtAbtMeContainer">
        <h3>Hi, I'm <span>Clara Gille</span>.</h3>
        <p className="lilPres">
          I'm a french student working in the digital field and living in Lille.
          Even if my studies push me to do mainly and exclusively full-stack development,
          I need to keep working and developing my creative mind and that's what drawing allows me to do.
        </p>

        <p className="lilPres">
          Unfortunately, I don't have time to draw a lot that's why I wanted to make my own portfolio :
          give free rein to my imagination while improving my web development skills.
          I started it at the beginning of January, coding between my courses, and I still plan to
          improve it by adding transitions, a better responsive,...
          But it takes some time since I'm doing everything on my own.
        </p>
      </div>
    </section>

    <div className="contactContainer">
      <div className="marginContainer">
        <h3>CONTACT</h3>
        <p>
          If you have any questions about me or just want to say hello,
          feel free to contact me on Intsagram or by email :
          email : clara.gille@orange.fr
        </p>
        <a href="https://www.instagram.com/nobodyisgone/?hl=fr"><i className="fa-brands fa-instagram icone"></i></a>
          <a href="https://www.linkedin.com/in/21claragille/"><i className="fa-brands fa-linkedin icone"></i></a>
      </div>

      <footer>
        <p>@ created by Clara Gille</p>
      </footer>
    </div>


  </div>;
};

export default AboutPage;
